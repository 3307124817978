/* .container{

} */
.title{
    color: rgba(0, 0, 0, 0.80);
    font-family: InterBold;
    font-size: 20px;
    font-style: normal;
    line-height: normal; 
    margin-bottom: 12px;
}
.subTitle{
    color: rgba(0, 0, 0, 0.80);
    font-family: InterRegular;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
}  
.containerName{
    display: flex;
    flex-direction: column;
    margin-top: 60px;
}
.titleName{
    color: rgba(0, 0, 0, 0.60);
    font-family: MontserratSemibold;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
}

.filterSearchBarTracking {
    width: clamp(252px, 100%, 252px);
    display: block;
    position: relative;
    margin-top: 31px;
}
  
.filterSearchBarTracking .searchBarTraking {
overflow: hidden;
border-radius: 30px;
position: relative;
box-shadow: 0px 4px 8px 0px #00000020;
}

.searchBarTraking input[type=text] {
width: clamp(252px, 100%, 242px);
padding: 11px 26px;
color: rgba(0, 0, 0, 0.40);
font-family: MontserratMedium;
font-size: 12px;
font-style: normal;
line-height: normal;
border-radius: 30px;
border: none;
max-height: 36px;
outline: none;
}
  
.searchBarTraking input[type=text]::placeholder {
color: rgba(0, 0, 0, 0.40);
font-family: MontserratMedium;
font-size: 12px;
font-style: normal;
line-height: normal;
}
  
.searchIconTracking {
display: block;
width: 26px;
height: 26px;
background: #F0E7E7;
position: absolute;
right: 8px;
top: 5px;
border-radius: 50%;
z-index: 1020;
background-repeat: no-repeat;
background-size: cover;
transition: all 180ms linear;
}

.searchIconTracking:hover {
background: #d2c9c9;
cursor: pointer;
background-repeat: no-repeat;
background-size: cover;
transition: all 180ms linear;
}

.nameContainer{
    border-radius: 5px;
    background: #FFF;
    text-align: center;
    width: 313px;
    margin-top: 5px;
}
.name{
    color: #000;
    font-family: MontserratMedium;
    font-size: 15px;
    font-weight: 500;
    padding: 11px 66px;
}
.containerStateLast{
    display: flex;
    width: 782px;
    height: auto;
    margin-top: 30px;
    justify-content: space-between;
}
.containerState{
    display: flex;
    flex-direction: column;
}
.titleState{
    color: rgba(0, 0, 0, 0.60);
    font-family: MontserratSemibold;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 5px;
}
.containerTextState{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 313px;
    height: 120px;
    border-radius: 5px;
    background: #FFF;
}
.state{
    color: #000;
    font-family: MontserratBold;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.subState{
    color: rgba(0, 0, 0, 0.40);
    font-family: MontserratMedium;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.titleLastActualitation{
    color: rgba(0, 0, 0, 0.60);
    font-family: MontserratSemibold;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 5px;
}
.containerLast{
    display: flex;
    flex-direction: column;
}
.containerTextLast{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 380px;
    height: 55px;
    border-radius: 5px;
    background: #FFF;
}
.dateState{
    color: #000;
    font-family: MontserratMedium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.containerHours{
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}
.containerHour{
    width: 180px;
    height: 55px;
    border-radius: 5px;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hour{
    color: #000;
    font-family: MontserratMedium;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.containerFormateHour{
    width: 180px;
    height: 55px;
    border-radius: 5px;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.format{
    color: #000;
    font-family: MontserratMedium;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;



}
.containerDataPackage{
    display: flex;
    width: 782px;
    height: auto;
    margin-top: 30px;
    justify-content: space-between;
}
.containerMeasures{
    display: flex;
    flex-direction: column;
}
.titleCommon{
    color: rgba(0, 0, 0, 0.60);
    font-family: MontserratSemiBold;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 5px;
}
.measuresContainer{
    width: 313px;
    height: 38px;
    border-radius: 5px;
    background: #FFF;   
    display: flex;
    justify-content: center;
    align-items: center;
}
.measures{
    color: #000;
    font-family: MontserratMedium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.containerCol1{
    display: flex;
    flex-direction: column;
}
.containerAreaZoneWeigth{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;
}
.dataMeasures{
    display: flex;
    flex-direction: column;
}
.measuresContainerData{
    width: 80px;
    height: 38px;
    border-radius: 5px;
    background: #FFF;   
    display: flex;
    justify-content: center;
    align-items: center;
}
.measuresContainerDataDown{
    width: 140px;
    height: 38px;
    border-radius: 5px;
    background: #FFF;   
    display: flex;
    justify-content: center;
    align-items: center;
}
.containerCol2{
    display: flex;
    /* justify-content: space-between; */
}
.containerOrigen{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}
.titleCommonCol2{
    color: rgba(0, 0, 0, 0.60);
    font-family: MontserratSemiBold;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
}
.origenContainer{
    width: 185px;
    height: 187px;
    border-radius: 5px;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 10px;
}
.directionTitle{
    color: #000;
    font-family: MontserratRegular;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    margin-bottom: 10px;
}
.directionText{
    color: #000;
    font-family: MontserratLight;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.directionSubTitle{
    color: rgba(0, 0, 0, 0.60);
    font-family: MontserratSemiBold;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.titleDirection{
    color: #000;
    font-family: MontserratSemiBold;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
}
.direction{
    width: 90px;
    height: 22px;
    flex-shrink: 0;
    color: #000;
    font-family: MontserratRegular;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.containerHistory{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
.titleHistoryDelivery{
    color: rgba(0, 0, 0, 0.60);
    font-family: MontserratSemiBold;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 5px;
}
.history{
    width: 250px;
    height: auto;
    border-radius: 5px;
    background: #FFF;
}
.historyContainer{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0px;
}
.historyDate{
    color: #000;
    font-family: MontserratRegular;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.statusHistory{
    color: rgba(0, 0, 0, 0.60);
    font-family: MontserratRegular;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100px;
    text-align: center;
}
.trackinError{
    font-size: 12px;
    color: #DF2E38;
    font-family: MontserratBold;
    padding-left: 30px;
    padding-top: 3px;
}


:root {

    --gutter: 30px;
    --border-width: 4px;
    --dot-diameter: 16px;
  }

  .containerTimeLine{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    width: 100%;
    max-width: 780px;
  }
  .timeline {

    padding: 20px 50px;
    position: relative;
    box-shadow: var(--container-shadow);
  }
  
  .timeline:before {
    margin-top: 30px;
    content: '';
    position: absolute;
    top: 0px;
    left: calc(33% + 15px); /* var(--gutter/2) */
    bottom: 0px;
    width: var(--border-width);
    background: #525151;
  }
  
  .timeline:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .entry {
    clear: both;
    text-align: left;
    position: relative;
  }
  
  .entry .title {
    margin-bottom: 0.5em;
    float: left;
    width: 33%;
    padding-right: var(--gutter);
    text-align: left;
    position: relative;
  }
  
  .entry .title:before {
    content: '';
    position: absolute;
    width: var(--dot-diameter);
    height: var(--dot-diameter);
    border: var(--border-width) solid rgb(255, 25, 0);
    background-color: #ff0000;
    border-radius: 50%;
    top: 50%;
    right: calc(-.5 * var(--dot-diameter));
    z-index: 99;
  }
  
  .entry .title h3 {
    color: #000;
    font-family: MontserratSemiBold;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  
  .entry .body {
    float: right;
    width: 66%;
    padding-left: var(--gutter);
    padding-top: 10px;
  }
  
  .statusOfGuide{
    color: rgba(0, 0, 0, 0.60);
    font-family: MontserratRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .hourStatusOfGuide{
    color: rgba(0, 0, 0, 0.60);
    font-family: MontserratLight;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .nameStatusOfGuide{
    color: #000;
    font-family: MontserratRegular;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }









@media screen and (max-width: 859px) {

    .nameContainer{
        border-radius: 5px;
        background: #FFF;
        text-align: center;
        width: 281px;
        margin-top: 5px;
    }

    .entry .title h3 {
        color: #000;
        font-family: MontserratSemiBold;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    


    .containerStateLast{
        display: flex;
        width: 460px;
        height: auto;
        margin-top: 30px;
        justify-content: space-between;
    }
    .containerTextState{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 90px;
        border-radius: 5px;
        background: #FFF;
    }
    .containerTextLast{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 250px;
        height: 40px;
        border-radius: 5px;
        background: #FFF;
    }
    .containerHour{
        width: 120px;
        height: 40px;
        border-radius: 5px;
        background: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .hour{
        color: #000;
        font-family: MontserratMedium;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .containerFormateHour{
        width: 120px;
        height: 40px;
        border-radius: 5px;
        background: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .format{
        color: #000;
        font-family: MontserratMedium;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .containerDataPackage{
        display: flex;
        width: 460px;
        height: auto;
        margin-top: 30px;
        justify-content: space-between;
        flex-direction: column;
    }
    .containerCol2{
        display: flex;
        justify-content: space-between;
    }
    /* .origenContainer{
        width: 120px;
        height: 80px;
        border-radius: 5px;
        background: #FFF;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 10px;        
    } */
    .titleCommonCol2{
        color: rgba(0, 0, 0, 0.60);
        font-family: MontserratSemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 5px;
    }

    .measuresContainer{
        width: 460px;
        height: 38px;
        border-radius: 5px;
        background: #FFF;   
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 712px) {

    .titleDirection{
        color: #000;
        font-family: MontserratSemiBold;
        font-size: 12px;
        font-style: normal;
        line-height: normal;
    }
    .directionTitle{
        color: #000;
        font-family: MontserratLight;
        font-size: 11px;
        font-style: normal;
        line-height: normal;
        margin-bottom: 10px;
    }

    
    .entry .title h3 {
        color: #000;
        font-family: MontserratSemiBold;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    


    .containerLast{
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }
    .containerStateLast{
        display: flex;
        width: 280px;
        height: auto;
        margin-top: 10px;
        justify-content: space-between;
        flex-direction: column;
        
    }
    .containerTextState{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 280px;
        height: 50px;
        border-radius: 5px;
        background: #FFF;
    }
    .containerTextLast{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 280px;
        height: 40px;
        border-radius: 5px;
        background: #FFF;
    }
    .measuresContainer{
        width: 280px;
        height: 38px;
        border-radius: 5px;
        background: #FFF;   
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .containerHour{
        width: 120px;
        height: 40px;
        border-radius: 5px;
        background: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .hour{
        color: #000;
        font-family: MontserratMedium;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .containerFormateHour{
        width: 120px;
        height: 40px;
        border-radius: 5px;
        background: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .format{
        color: #000;
        font-family: MontserratMedium;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .containerDataPackage{
        display: flex;
        width: 280px;
        height: auto;
        margin-top: 30px;
        /* justify-content: center; */
        flex-direction: column;
    }
    .containerCol2{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
    .origenContainer{
        width: 130px;
        height: 140px;
        border-radius: 5px;
        background: #FFF;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 10px;
    }
    .titleCommonCol2{
        color: rgba(0, 0, 0, 0.60);
        font-family: MontserratSemiBold;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 5px;
        margin-top: 10px;
    }
    .history{
        width: 280px;
        height: auto;
        border-radius: 5px;
        background: #FFF;
    }
    .container{
        padding-left: 20px;
    }

}