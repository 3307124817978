#wrapper {
  display: block;
  width: clamp(calc(100vw - 204px), 100%, 100vw);
  height: clamp(100vh, 100vh, 100vh);
  background: #F2F2F2;
  position: relative;
  top: 0;
  left: 204px;
}

#wrapContainer {
  display: block;
  width: calc(100vw - 204px);
  height: 100%;
  padding: 80px 100px;
  overflow-y: auto;
}


@media screen and (max-width: 834px) {
  #wrapContainer {
    padding: 80px 40px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 480px) {
  #wrapper {
    width: clamp(100vw, 100%, 100vw);
    height: clamp(100dvh, 100dvh, 100dvh);
    position: relative;
    left: 0px
  }

  #wrapContainer {
    padding: 44px 37px;
    width: 100vw;
  }
}