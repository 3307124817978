.card {
  position: relative;
  background: white;
  box-shadow: 0px 4px 8px 0px #00000020;
  border-radius: 4px;

  width: clamp(283px, 283px, 283px);
  height: clamp(124px, 124px, 124px);
  margin-bottom: unset;
}

.smallCard {
  position: relative;
  width: 100%;
  display: block;
  padding: 17px 22px;

}

.smallCard>* {
  z-index: 1060;
}

.cardTitle {
  position: relative;
  color: #828282;
  font-family: "InterSemibold";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

}

.cardValue {
  position: relative;
  color: #000;
  font-family: "InterRegular";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.56px;
  margin-top: 12px;
  display: block;
}

.cardName {
  position: relative;
  color: rgba(0, 0, 0, 0.30);
  font-family: "InterBold";
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cardBgImage {
  display: block;
  position: absolute;
  z-index: 1040;
  right: 0;
  bottom: 0;
}

.mediumCard {
  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(99, 99, 99, 0.20);
  border-radius: 4px;
  width: clamp(420px, 420px, 420px);
  height: clamp(155px, 155px, 155px);

}

.mCardContent {
  padding: 17px 0px;
  text-align: center;
}

.mCardTitle {
  color: #828282;
  font-family: "InterSemibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 25px;
}

.mCardValue {
  color: #000;
  font-family: "InterSemibold";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media screen and (max-width: 834.99px) {
  .mCardContent {
    padding: 32px 0px;
    text-align: center;
  }
  .mCardTitle {
    font-size: 12px;
    margin-bottom: 8px;
  }
  .mCardValue { 
    font-size: 20px; 
  }
  .mediumCard {
    width: clamp(250px, 250px, 250px);
    height: clamp(110px, 110px, 110px);
  }

  .card {
    width: clamp(220px, 220px, 220px);
    height: clamp(115px, 115px, 115px);
    margin-bottom: 38px;
  }

  .smallCard {
    max-width: inherit;
    width: clamp(220px, 220px, 220px);
    height: clamp(115px, 115px, 115px);
  }

  .smallCard:not(:last-child) {
    margin-bottom: 35px;
  }

  .cardBgImage {
    width: clamp(140px, 140px, 140px);
  }
}


@media screen and (max-width: 480.99px) {

  .card {
    width: clamp(280px, 280px, 280px);
    height: clamp(125px, 125px, 125px);
    margin: 0 auto;
    margin-bottom: 1.5rem;
  }

  .smallCard {

    width: clamp(280px, 280px, 280px);
    height: clamp(125px, 125px, 125px);
  }

  .smallCard:not(:last-child) {
    margin-bottom: 35px;
  }
}