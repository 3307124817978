h1{
    font-size: 20px;
    font-family: InterBold;
}
h3{
    margin-top: 12px;
    font-family: InterRegular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.date{
    margin-top: 130px;
}
.dateTitle{
    color: rgba(0, 0, 0, 0.80);
    font-family: InterMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.currentDate{
    color: rgba(0, 0, 0, 0.60);
    font-family: InterMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.btnGenerar{
    width: 150px;
    height: 50px;
    background: #EB1D36;
    color: aliceblue;
    font-size: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 5px;
    margin-top: 30px;
    
}
.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.warningMessage {
    color: #FF1212; 
    font-size: 9px;
    margin-top: 10px;
    margin-left: 12px;
    font-weight: 500;
}
.warningContainer {
    background-color: #ffffff; 
    padding: 14px, 36px, 14px; 
    margin-top: 20px;
    border-radius: 5px; 
    width: 400px;
    height: 50px;
  }
.warningIcon {
    margin-top: 10px;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-left: 15px;
}
.warningContent {
    display: flex;
    align-items: center;
}
.successMessage {
    color: rgba(0, 0, 0, 0.80);
    font-weight: 500;
    font-size: 12px;
    margin-top: 10px;
}
.downloadButton {
    background-color: #4caf50;  
    color: white;
}