.modalUserContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  min-height: 138px;
}

.contentName {
  color: #C92F2F;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.contentButtons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.continueButton {
  border-radius: 20px;
  background: #FC5555;
  padding: 8px 21px;
  outline: none;
  border: none;
  transition: all 180ms linear;

  &>span {
    color: white;
  }

  &:hover {
    background: #d64242;
    cursor: pointer;
    transition: all 180ms linear;
  }
}

.cancelButton {
  border-radius: 20px;
  background: transparent;
  padding: 8px 21px;
  outline: none;
  border: none;
  transition: all 180ms linear;

  &>span {
    color: #000000CC;
  }

  &:hover {
    background: #f5f5f5;
    cursor: pointer;
    transition: all 180ms linear;
  }
}