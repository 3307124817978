.modalUserContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  /* min-height: 438px;
  min-width: 600px; */
  /* width: 900px; */
}

.contentName {
  color: #C92F2F;
  font-family: MontserratSemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.contentButtons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.continueButton {
  border-radius: 20px;
  background: #FC5555;
  padding: 8px 21px;
  outline: none;
  border: none;
  transition: all 180ms linear;

  &>span {
    color: white;
  }

  &:hover {
    background: #d64242;
    cursor: pointer;
    transition: all 180ms linear;
  }
}

.cancelButton {
  border-radius: 20px;
  background: transparent;
  padding: 8px 21px;
  outline: none;
  border: none;
  transition: all 180ms linear;

  &>span {
    color: #000000CC;
  }

  &:hover {
    background: #f5f5f5;
    cursor: pointer;
    transition: all 180ms linear;
  }
}

/* Estilos para el segundo modal */
.modalSegundo {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid rgba(145, 131, 131, 0.3);
  width: 100%;
  max-width: 250px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1091;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 4px 8px 0px #00000020;
}
.modalSegundo input {
  text-align: center;
  width: calc(100% - 20px);
  border-radius: 10px;
  border: 1px solid rgba(145, 131, 131, 0.30);  
}
.textSaldo{
  font-size: 22px;
  font-family: MontserratMedium;
}
.saldoModal{
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 30px;
  font-family: MontserratBold;
}
.modalSegundo button {
  color: white;
  border: none;
  cursor: pointer;
}
.inputModal{
  width: 217px;
  height: 40px;
}
.btnModalSaldo{
  background: #DF2E38;
  color: #fff;
  font-size: 10px;
  padding: 9px 44px;
  border-radius: 10px;
  margin-top: 10px;
}
.imgClose{
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
  opacity: 0.5;

}
.imgClose :hover{
  opacity: 1;
}
.containerButtonModal{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.btnAdd{
  width: 67px;
  height: 30px;
  background: #EB1D36;
  color: aliceblue;
  font-size: 10px;
  border-radius: 5px;
  margin-right: 10px;
}
.btnRedu{
  width: 67px;
  height: 30px;
  background: rgba(0, 0, 0, 0.8);
  color: aliceblue;
  font-size: 10px;
  border-radius: 5px;
}
.tdTable{
  font-size: 12px;
  text-align: center;
}
.thTable{
  font-size: 12px;
  text-align: center;
}
.tableContainer{
  max-width: 780px;
  width: 100%;
  height: 180px;
  overflow-x: auto;
  overflow-y: auto;
  margin-top: 10px;
  box-shadow: 0px 4px 8px 0px #00000020;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.renglon {
  border-bottom: 1px solid rgba(201, 201, 201, 0.50);
  padding: 8px;
  border-bottom: none;
  border-right: none;
}

.title{
  font-size: 20px;
}
.saldo{
  font-family: MontserratBold;
  font-size: 22px;
  margin: 10px 0px;
}
.textSaldo{
  color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
  margin-top: 10px ;
}
.textButtons{
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  margin-bottom: 5px;
  font-family: MontserratBold;
}

.containerTableMin{
  width: 100%;
  max-width: 319px;
  height: 110px;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(199, 201, 204, 0.20);
  margin: 2px;
}
.topContainerTableMin{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.bottomContainerTableMin{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.containerTextTableMin{
  display: flex;
  flex-direction: column;
}
.titleTextTableMin{
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.textTableMin{
  color: rgba(0, 0, 0, 0.60);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.renglonth{
  background-color: #fff;
  position: sticky;
  top: 0;

  border-bottom: 1px solid rgba(201, 201, 201, 0.50);
  padding: 8px;
  border-bottom: none;
  border-right: none;
}






@media screen and (max-width: 840px) {
  
  .tableContainer{
    max-width: 600px;
    width: 100%;
    height: 180px;
    overflow-x: auto;
    overflow-y: auto;
    margin-top: 10px;
    box-shadow: 0px 4px 8px 0px #00000020;
  }

}
@media screen and (max-width: 680px) {
  
  .tableContainer{
    max-width: 500px;
    width: 100%;
    height: 180px;
    overflow-x: auto;
    overflow-y: auto;
    margin-top: 10px;
    box-shadow: 0px 4px 8px 0px #00000020;
  }

}
@media screen and (max-width: 580px) {
  
  .tableContainer{
    max-width: 400px;
    width: 100%;
    height: 180px;
    overflow-x: auto;
    overflow-y: auto;
    margin-top: 10px;
    box-shadow: 0px 4px 8px 0px #00000020;
  }

}
@media screen and (max-width: 480px) {
  
  .tableContainer{
    max-width: 350px;
    width: 100%;
    height: 180px;
    overflow-x: auto;
    overflow-y: auto;
    margin-top: 10px;
    box-shadow: 0px 4px 8px 0px #00000020;
  }

}