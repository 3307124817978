#page>h1 {
  color: rgba(0, 0, 0, 0.80);
  font-family: "InterRegular";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 12px;
}

#page>p {
  color: rgba(0, 0, 0, 0.80);
  font-family: "InterRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#page .body {
  display: block;
  margin-top: 42px;
}

.filterSearchBar {
  width: clamp(441px, 100%, 441px);
  display: block;
  position: relative;
}

.filterSearchBar .searchBar {
  overflow: hidden;
  border-radius: 30px;
  position: relative;
  box-shadow: 0px 4px 8px 0px #00000020;
}

.searchBar input[type=text] {
  width: clamp(441px, 100%, 441px);
  padding: 11px 26px;
  color: rgba(0, 0, 0, 0.40);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 30px;
  border: none;
  max-height: 36px;
  outline: none;
}

.searchBar input[type=text]::placeholder {
  color: rgba(0, 0, 0, 0.40);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.searchIcon {
  display: block;
  width: 26px;
  height: 26px;
  background: #F0E7E7;
  position: absolute;
  right: 8px;
  top: 5px;
  border-radius: 50%;
  z-index: 1020;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 180ms linear;
}

.searchIcon:hover {
  background: #d2c9c9;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 180ms linear;
}

.containerFilters {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.filterTools {
  display: flex;
  flex-flow: row nowrap;
  gap: 32px;
  align-items: center;
  justify-content: flex-end;
}

.filterTools>button {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 11px 16px;
  gap: 8px;
  height: clamp(36px, 36px, 36px);
  border: none;
  border-radius: 5px;
  transition: all 180ms linear;
  box-shadow: 0px 4px 8px 0px #00000020;
}

.filterTools>button>span {
  color: #FFF;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


.filterTools>button:hover {
  cursor: pointer;
  transition: all 180ms linear;
}

button#refresh {
  background: #F1892D;
}

button#refresh:hover {
  background: #e37f28;
}

button#add {
  background: #EB1D36;
}

button#add:hover {
  background: #d6192f;
}


select#filterSelect {
  height: clamp(36px, 36px, 36px);
  width: clamp(155px, 155px, 155px);
  padding-left: 14px;
  color: rgba(0, 0, 0, 0.50);
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 4px 8px 0px #00000020;

}


.actionTools {
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
}

.toolButton {
  width: 50px;
  height: 50px;
  transform: scale(1.5);
  transition: all 180ms linear;
  padding-left: 30px;
  padding-bottom: 10px;
}
.nextArrow{
  width: 50px;
  height: 50px;
}
.toolButton:hover {
  transform: scale(2);
  cursor: pointer;
  transition: all 180ms linear;
}

@media screen and (max-width: 834px) {

  .containerFilters {
    flex-flow: column wrap;
    gap: 28px;
    align-items: flex-start;
  }

  .filterSearchBar {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 20px;
  }

  .searchBar {
    width: clamp(287px, 287px, 287px);
  }

  .searchBar input[type=text] {
    width: clamp(287px, 287px, 287px);
  }
}

@media screen and (max-width: 480px) {

  .filterTools {
    gap: 13px;
    justify-content: center;
    width: 100%;
  }

  .filterTools button span {
    display: none;
  }

  .filterTools button {
    border-radius: 50%;
    width: clamp(30px, 30px, 30px);
    height: clamp(30px, 30px, 30px);
    padding: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  #filterSelect {
    width: clamp(155px, 155px, 155px);
  }

  .searchBar input[type=text],
  .searchBar {
    width: 100%;
    max-width: 100%;
  }

  #page .body {
    margin-top: 32px;
  }

  .actionTools {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    flex-grow: 1;
    gap: unset;
  }

  
}