.modal {
  position: fixed;
  z-index: 1080;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modalCloseBg {
  position: fixed;
  z-index: 1080;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modalContent {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 100%;
  max-width: 500px;
  height: 640px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1090;
  display: block;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #0000009e;
}

.modalAnchor {
  background-color: #fefefe;
  margin: auto;
  padding: 57px;
  border: 1px solid #888;
  width: 100%;
  max-width: 560px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1090;
  display: block;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #0000009e;
}

.modalAnchor2 {
  background-color: #fefefe;
  margin: auto;
  padding: 46px 57px;
  border: 1px solid #888;
  width: 100%;
  max-width: 560px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1090;
  display: block;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #0000009e;
}

.modalAnchor3 {
  background-color: #fefefe;
  margin: auto;
  padding: 30px 50px;
  border: 1px solid #888;
  width: 100%;
  max-width: 900px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1090;
  display: block;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #0000009e;
}

.modalClose {

  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  display: block;
  line-height: 0;
}

.modalContent .modalClose {
  position: relative;
  right: 0.5rem;
  top: 0.5rem;
}

.modalAnchor2 .modalClose {
  position: relative;
  right: -2rem;
  top: -1rem;
}

.modalAnchor3 .modalClose {
  position: relative;
  right: -2rem;
  top: -1rem;
}

.modalClose:hover {
  cursor: pointer;
  color: #353535;
}

.modalContent .contentBody {
  margin: 20px 0px;
}

.modalAnchor .contentBody {
  margin: unset;
}

.modalAnchor3 .contentBody {
  margin: unset;
}

.modalAnchor .contentBody>h1,
.modalAnchor2 .contentBody>h1,
.modalAnchor3 .contentBody>h1 {
  text-align: start;
}

.contentBody>h1 {
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

@media screen and (max-width: 834px) {

  .modalContent {
    padding: 25px;
    max-width: 450px;
    height: 600px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .modalAnchor,
  .modalAncho2,
  .modalAnchor3 {

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .modalCloseBg {
    padding-top: unset;
  }
}

@media screen and (max-width: 480px) {

  .modalContent {
    max-width: calc(100vw - 32px);
    height: auto;
  }

  .modalAnchor,
  .modalAncho2,
  .modalAnchor3 {
    max-width: calc(100vw - 33px);
    padding: 35px;
  }

  .modalCloseBg {
    padding-top: unset;
  }
  .modalAnchor2 .modalClose {
    right: -1rem;
    top: -0.5rem;
  }
  .modalAnchor3 .modalClose {
    right: -1rem;
    top: -0.5rem;
  }
  
  div[dataType=doubleSpace] {
    width: 100%;
  }
}