.pageTitle {
  color: rgba(0, 0, 0, 0.80);
  font-family: "InterBold";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sectionSmallCards {
  margin-top: 2rem;
  display: flex;
  gap: 28px;
  margin-bottom: 39px;
}


.sectionMediumCards {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: flex-start;
  gap: 67px;
  margin-bottom: 31px;
}
.graphicWrapper {
  border-radius: 4px;
}
.graphicWrapper img {
  min-width: 900px;
  max-width: 100%;
  display: block;
  box-shadow: 0px 2px 8px 0px rgba(99, 99, 99, 0.20);
}

@media screen and (max-width:834.99px) {

  .graphicWrapper img {
    min-width: unset;
  }

  .sectionMediumCards {
    justify-content: space-between;
    margin-bottom: 52px;
    gap: unset;
  }

  .sectionSmallCards {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: unset;
    margin-bottom: unset;
  }

}

@media screen and (max-width: 480.99px) {

  .sectionSmallCards {
    margin-top: 1.5rem;
    display: block;
  }

}