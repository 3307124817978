section#wrapper {
  display: flex;
  flex-flow: row nowrap;
  width: clamp(100vw, 100vw, 100vw);
  height: clamp(100vh, 100vh, 100vh);
}

.logoIcon {
  width: 61px;
  height: 70px;
  display: block;
  position: absolute;
  left: 32px;
  top: 24px;
  background-repeat: no-repeat;
  background-size: contain;
}

.bgLeftImage {
  width: clamp(50%, 50%, 50%);
  height: 100dvh;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bgLeftTitles {
  background: linear-gradient(180deg, #000000A6, #D9D9D900);
  width: 100%;
  height: inherit;
  padding: 61px 63px;
}

.bgLeftTitles h1 {
  color: #FFFEFE;
  font-family: 'PoppinsSemiBold';
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.bgLeftTitles h2 {
  color: #FFFEFE;
  font-family: "PoppinsExtraBold";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.bgLeftImage::before {
  display: block;
  width: clamp(50%, 50%, 50%);
  height: 100dvh;
}

.bgRightForm {
  width: auto;
  flex-grow: 1;
  height: 100dvh;
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.formContainer h1 {
  color: #000;
  font-family: MontserratBold;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  display: block;
  margin-bottom: 12px;
}

.formContainer p {
  color: rgba(0, 0, 0, 0.45);
  font-family: MontserratSemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}


.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: clamp(346px, 346px, 346px);
  height: auto;
}

.formContainer>div {
  margin-bottom: 3rem;
}

.formContainer form {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: inherit;
}

.inputForm {
  width: inherit;
  margin-bottom: 1rem;
}

.inputForm label {

  color: #000;
  font-family: MontserratMedium;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.inputForm input {
  height: clamp(55px, 55px, 55px);
  width: 100%;
  border: none;
  border-bottom: 1px solid #2609097b;
  margin-bottom: -1px;
  background: transparent;

  color: rgba(0, 0, 0, 0.50);
  font-family: MontserratMedium;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  outline: none;
  padding-left: 12px;
}

.inputForm span {
  color: #FC5555;
  font-family: MontserratMedium;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.formContainer form button[type=submit] {
  width: 100%;
  height: clamp(52px, 52px, 52px);
  border-radius: 10px;
  background: #FC5555;
  outline: none;
  border: none;
  transition: all 180ms linear;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.formContainer form button[type=submit]:hover {
  background: #cd4040;
  transition: all 180ms linear;
  cursor: pointer;
}

.formContainer form button[type=submit]>span {
  color: #FFF;
  font-family: MontserratMedium;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.loadingIcon {
  display: block;
  animation: 1.5s rotationLoading linear infinite;
  position: relative;
}


@keyframes rotationLoading {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}


@media screen and (max-width: 834.99px) {
  .bgLeftImage {
    display: none;
  }
}