.inputForm {
  display: block;
  margin-bottom: -1px;
  border-bottom: 1px solid #EFDBDB;
  max-width: 120px;
  margin-bottom: 20px;
}

.inputForm label {
  color: rgba(0, 0, 0, 0.60);
  font-family: Montserrat;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inputForm input[type=text],
.inputForm input[type=email],
.inputForm input[type=password] {
  width: 100%;
  max-width: 120px;
  border: none;
  outline: none;

  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding-top: 8px;
  padding-bottom: 7px;

  max-height: 30px;
}

.formContainer {
  margin-top: 42px;
}

.formContainer form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.formFooterTools {
  margin-top: 44px;
  display: flex;
  flex-flow: row nowrap;
  margin-left: auto;
  gap: 8px;
}

.formFooterTools button {
  width: clamp(100px, 100px, 100px);
  padding-top: 11px;
  padding-bottom: 10px;
  border-radius: 5px;
  border: none;
}

.formFooterTools button:hover {
  cursor: pointer;
}

.formFooterTools button span {
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

button.cancelButton {
  color: #DF2E38;
  background: #fff;
  transition: all 180ms linear;
}

button.cancelButton:hover {
  color: #bc252d;
  background: #f7f7f7;
  transition: all 180ms linear;
}

button.createButton {
  color: #fff;
  background: #DF2E38;
  transition: all 180ms linear;
}

button.createButton:disabled {
  background: rgb(172, 172, 172);
  cursor: unset;
}

button.createButton:not(:disabled):hover {
  background: #bc252d;
  transition: all 180ms linear;
}