.tableFooterTools {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  min-height: 2rem;
  max-height: 2rem;
  margin-top: 12px;
}

.countRegs {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.countRegs>p {
  color: rgba(0, 0, 0, 0.50);
  font-family: "InterRegular";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#selectRows {
  background: transparent;
  border: none;

  color: #000;
  font-family: "InterRegular";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pagination {
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.paginationCircle,
.paginationCircleArrows {
  width: clamp(30px, 30px, 30px);
  height: clamp(30px, 30px, 30px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #D9D9D9;
  border-radius: 50%;
  border: none;
  transition: all 180ms linear;
}
.paginationCircleActive{
  width: clamp(30px, 30px, 30px);
  height: clamp(30px, 30px, 30px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #DF2E38;
  color: #FFFFFF;
  border-radius: 50%;
  border: none;
  transition: all 180ms linear;
}



.paginationCircle:not(:disabled):hover,
.paginationCircleArrows:not(:disabled):hover {
  background: #c6c5c5;
  cursor: pointer;
  transition: all 180ms linear;
}

.paginationCircle>span {
  color: #000;
  font-family: "InterRegular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.paginationCircleArrows {
  width: clamp(24px, 24px, 24px);
  height: clamp(24px, 24px, 24px);
}

.showRegs>p {
  color: rgba(0, 0, 0, 0.50);
  font-family: "InterRegular";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.showRegs>p>strong {
  color: #000;
  font-weight: 500;
}

@media screen and (max-width: 834px) {

  .paginationCircle,
  .paginationCircleArrows {
    width: clamp(20px, 20px, 20px);
    height: clamp(20px, 20px, 20px);
  }

  .paginationCircleArrows {
    width: clamp(15px, 15px, 15px);
    height: clamp(15px, 15px, 15px);
  }

  .paginationCircle>span {
    font-size: 10px;
  }

  .countRegs {
    flex-direction: column;
    align-items: flex-start;
  }
}


@media screen and (max-width: 480px) {

  .showRegs>p,
  .countRegs>p,
  #selectRows {
    font-size: 5px;
  }
}