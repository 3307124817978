#tableContainer {
    width: 100%;
    margin-top: 20px;
  }
 
  #basicTable {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0px 4px 8px 0px #00000020;
  }
 
  #tableContainer tbody tr:hover td {
    background: #F7F7F7;
  }
 
  #tableContainer th {
    padding-top: 17px;
    padding-bottom: 17px;
 
    color: #000;
    font-family: InterRegular;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: #FFF;
    text-align: center;
    border-bottom: 1px solid rgba(201, 201, 201, 0.50);
    margin-bottom: -1px;
  }
 
  #tableContainer td {
    padding: 14px 20px;
    background: #FFF;
    color: #000;
    font-family: InterRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    border-bottom: 1px solid #FFE7E7;
    margin-bottom: -1px;
  }
 
  .actionTools {
    display: flex;
    flex-flow: row nowrap;
    gap: 16px;
  }
 
  .toolButton {
    width: clamp(24px, 24px, 24px);
    height: clamp(24px, 24px, 24px);
    border-radius: 50%;
    background: #FFEFEF;
    border: none;
    transform: scale(1);
    transition: all 180ms linear;
  }
 
  .toolButton:hover {
    transform: scale(1.5);
    cursor: pointer;
    transition: all 180ms linear;
  }
 
  @media screen and (max-width: 834px) {
    #tableContainer {
      width: auto;
      overflow-x: auto;
      margin-top: 70px;
    }
 
    #basicTable {
      width: clamp(944px, 944px, 944px);
      border-collapse: collapse;
    }
  }
 
  @media screen and (max-width: 480px) {
    #tableContainer {
      margin-top: 24px;
    }
 
    #basicTable {
      width: 100%;
      border-collapse: collapse;
    }
 
    #tableContainer td {
      padding: 10px 15px;
      background: #FFF;
      font-family: InterRegular;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: start;
      border-bottom: none;
      margin-bottom: unset;
 
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: calc(calc(100vw - 74px)/3);
    }
 
    #tableContainer td span:first-child {
 
      color: rgba(0, 0, 0, 0.60);
    }
 
    #tableContainer td span:last-child {
      color: #000;
    }
 
    #tableContainer thead {
      display: none;
    }
 
    .cellStatus span:last-child {
      color: #FFF;
      font-family: InterRegular;
      font-size: 5px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      width: 27px;
      height: 11px;
    }
 
    .cellStatus span[datatype=success] {
      background: #6DD22F;
    }
 
    .cellStatus span[datatype=cancel] {
      background: #DF2E38;
    }
 
    .rowBottom,
    .rowTop {
      display: flex;
      flex-flow: row nowrap;
    }
 
    .actionTools {
      gap: 6px;
    }
 
    .toolButton {
      width: clamp(16px, 16px, 16px);
      height: clamp(16px, 16px, 16px);
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
    }
 
    .toolButton img {
      width: clamp(6px, 6px, 6px);
      height: clamp(6px, 6px, 6px);
    }
  }