.modalResponse {
  position: fixed;
  top: 0;
  left: 0;
  width: clamp(100vw, 100vw, 100vw);
  height: clamp(100vh, 100vh, 100vh);
  z-index: 1090;
}

.modalClose {
  display: block;
  background: #5348484d;
  position: inherit;
  top: 0;
  left: 0;
  width: clamp(100vw, 100vw, 100vw);
  height: clamp(100vh, 100vh, 100vh);
  z-index: 1091;
}

.modalContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
  width: clamp(350px, 350px, 350px);
  height: clamp(350px, 350px, 350px);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 1095;
}

.containerHeaders {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 39px;
}

.containerImage {
  display: block;
  width: 100%;
  height: 40%;
}

.containerImage img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 836px) {
}

@media screen and (max-width: 480px) {
  .modalContainer {
    width: clamp(250px, 250px, 250px);
    height: clamp(250px, 250px, 250px);
  }
  .containerHeaders {
    font-size: 12px;
    margin-bottom: 23px;
  }
}
