.inputForm {
  display: block;
  margin-bottom: -1px;
  border-bottom: 1px solid #EFDBDB;
  max-width: 120px;
  margin-bottom: 20px;
}

.textAreaForm {
  display: block;
  margin-bottom: -1px;
  border-bottom: 1px solid #EFDBDB;
  max-width: 283px;
}

.inputForm label,
.textAreaForm label {
  color: rgba(0, 0, 0, 0.60);
  font-family: Montserrat;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inputForm input[type=text],
.inputForm input[type=email],
.inputForm input[type=password] {
  width: 100%;
  max-width: 120px;
  border: none;
  outline: none;

  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding-top: 8px;
  padding-bottom: 7px;

  max-height: 30px;
}

div[dataType=doubleSpace] {
  width: clamp(283px, 283px, 283px);
  max-width: unset;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.textAreaForm textarea {
  width: 100%;
  max-width: 283px;
  border: none;
  outline: none;

  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding-top: 8px;
  padding-bottom: 7px;

  max-height: 70px;
  resize: none;
}

.formContainer {
  margin-top: 18px;
}

.formContainer section.formCard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.formFooterTools {
  margin-top: 16px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  gap: 8px;
}

.formFooterTools button {
  width: clamp(100px, 100px, 100px);
  padding-top: 11px;
  padding-bottom: 10px;
  border-radius: 5px;
  border: none;
  display: block;
}

.formFooterTools button:hover {
  cursor: pointer;
}

.formFooterTools button span {
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

button.cancelButton {
  color: #DF2E38;
  background: #fff;
  transition: all 180ms linear;
  margin-left: auto;
}

button.cancelButton:hover {
  color: #bc252d;
  background: #f7f7f7;
  transition: all 180ms linear;
}

button.createButton {
  color: #fff;
  background: #DF2E38;
  transition: all 180ms linear;
}

button.createButton:disabled {
  background: rgb(172, 172, 172);
  cursor: unset;
}

button.createButton:not(:disabled):hover {
  background: #bc252d;
  transition: all 180ms linear;
}

form>h2 {
  color: rgba(0, 0, 0, 0.50);
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
}

.switchContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 10px;
}

.switchContainer>p {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderRound {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.sliderRound::before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.sliderRound {
  background-color: #FC5555;
}

input:focus+.sliderRound {
  box-shadow: 0 0 1px #FC5555;
}

input:checked+.sliderRound::before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

@media screen and (max-width: 480px) {
  .formContainer {
    margin-top: 20px;
  }

  .inputForm {
    margin-bottom: 10px;
  }

  .formFooterTools {
    flex-flow: column nowrap;
    gap: 8px;
  }

  .formFooterTools button {
    width: clamp(197px, 197px, 197px);
    margin: 0 auto;
  }

  div[datatype="emailForm"],
  div[datatype="passForm"] {
    width: 100%;
    max-width: 100% !important;
  }

  .inputForm label,
  .textAreaForm label {
    display: block;
  }

  .inputForm input[type=text],
  .inputForm input[type=email],
  .inputForm input[type=password] {
    width: 100%;
    max-width: 50%;
    border: none;
    outline: none;

    color: #000;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    padding-top: 6px;
    padding-bottom: 5px;

    max-height: 24px;
  }

  div[dataType=doubleSpace] {
    width: 100%;
  }
}