.userOptions {
  margin-top: 4rem;
}

.optionButton {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;

  border: none;
  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(99, 99, 99, 0.20);
  padding: 25px 14px;
  width: 100%;
  max-width: 440px;
  margin: 0px auto 30px auto;
  transition: all 180ms linear;
}

.optionButton:hover {
  cursor: pointer;
  box-shadow: 0px 2px 8px 0px rgba(99, 99, 99, 0.40);
  transition: all 180ms linear;
}

.userOptions .optionButton:last-child {
  margin-bottom: unset;
}

.optionButton img {
  width: clamp(80px, 80px, 80px);
  height: clamp(80px, 80px, 80px);
}

.optionButton span {
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (max-width: 834px) {
  .optionButton span {
    font-size: 20px;
  }
  .optionButton {
    max-width: 400px;
  }
}

@media screen and (max-width: 480px) {

  .optionButton {
    gap: 40px;
  }
  .optionButton img {
    width: auto;
    height: clamp(50px, 50px, 50px);
  }
  .userOptions {
    margin-top: 40px;
    
  }
}