*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F5F5F5;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100vw;
  height: 100dvh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: inherit;
  height: inherit;
}

/* Fonts */

@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"),
    url("./assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratExtraBold";
  src: local("MontserratExtraBold"),
    url("./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratLight";
  src: local("MontserratLight"),
    url("./assets/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratMedium";
  src: local("MontserratMedium"),
    url("./assets/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratRegular";
  src: local("MontserratRegular"),
    url("./assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratSemibold";
  src: local("MontserratSemibold"),
    url("./assets/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
}


@tailwind base;
@tailwind components;
@tailwind utilities;
/* *INTER */
@font-face {
  font-family: "InterBold";
  src: local("InterBold"),
    url("./assets/fonts/Inter/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "InterExtraBold";
  src: local("InterExtraBold"),
    url("./assets/fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "InterLight";
  src: local("InterLight"),
    url("./assets/fonts/Inter/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "InterMedium";
  src: local("InterMedium"),
    url("./assets/fonts/Inter/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "InterRegular";
  src: local("InterRegular"),
    url("./assets/fonts/Inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "InterSemibold";
  src: local("InterSemibold"),
    url("./assets/fonts/Inter/Inter-SemiBold.ttf") format("truetype");
} 

/* *Poppins */
@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
    url("./assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsExtraBold";
  src: local("PoppinsExtraBold"),
    url("./assets/fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsLight";
  src: local("PoppinsLight"),
    url("./assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsMedium";
  src: local("PoppinsMedium"),
    url("./assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
    url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsSemibold";
  src: local("PoppinsSemibold"),
    url("./assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
} 


@media screen and (max-width: 480px) {
  body {
    overflow-y: auto;
  }
}
