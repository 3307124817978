#drawer {
  background: #DF2E38;
  height: clamp(100vh, 100vh, 100vh);
  width: clamp(204px, 204px, 204px);
  position: absolute;
  left: 0;
  top: 0;
  overflow-y: auto;
  scrollbar-width: thin; /* Para navegadores Firefox */
  scrollbar-color: transparent transparent; /* Para navegadores Firefox */
  -ms-overflow-style: none; /* Para navegadores Internet Explorer y Edge */
}

#drawer::-webkit-scrollbar {
  width: 5px; /* Ancho de la barra de desplazamiento para navegadores WebKit (Chrome, Safari, etc.) */
}

#drawer::-webkit-scrollbar-thumb {
  background-color: transparent; /* Color del pulgar de la barra de desplazamiento */
}
.drawerLogo {
  width: 100%;
  height: clamp(80px, 80px, 80px);
  position: relative;
  border-bottom: 1px solid #FEE1E1;
  margin-bottom: -1px;
}

.drawerLogo img {
  height: 60%;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#drawerUser {
  display: block;
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #FEE1E1;
  margin-bottom: -1px;
  height: clamp(166px, 166px, 166px);
}


#userInfo {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.userIcon {
  width: clamp(70px, 70px, 70px);
  height: clamp(70px, 70px, 70px);
  background: #E56A6A;
  margin-bottom: 3px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.userIcon span {
  color: #FFF;
  text-align: justify;
  font-family: MontserratSemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.2px;
}

#userInfo>h1 {
  color: #FFF;
  text-align: justify;
  font-family: MontserratRegular;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#userInfo>h2 {
  color: rgba(255, 255, 255, 0.80);
  text-align: justify;
  font-family: MontserratRegular;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#drawerNav {
  position: relative;
}

#navLinks {
  padding-top: 26px;
}

#navLinks a,
a.logout {
  display: flex;
  min-height: 60px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: transparent;
  padding-left: 29px;
  gap: 18px;

  color: #FFF;
  text-align: justify;
  font-family: MontserratSemiBold;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.55px;
  text-decoration: none;
  position: static;

  transition: all 180ms linear;
}

#closeSession {
  display: block;
  height: auto;
  position: relative;
  bottom: 0;
  margin-top: 60px;
  width: clamp(204px, 204px, 204px);
  
}

#navLinks a:hover,
a.logout:hover {
  background: #dc0e18cf;
  transition: all 180ms linear;
}

#mobileDrawer {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  height: clamp(75px, 75px, 75px);
  background: #DF2E38;
}

#mobileDrawer>div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding-left: 20px;
  align-items: center;
}

button.mobileMenu {
  width: clamp(24px, 24px, 24px);
  height: clamp(24px, 24px, 24px);
  background: transparent;
  border: none;
}

#mobileDrawer .drawerLogo {
  position: inherit;
}

#mobileDrawer .drawerLogo>img {
  width: auto;
  height: -webkit-fill-available;
}

.mobileNavMenu {
  position: fixed;
  background: #DF2E38;
  top: 75px;
  left: 0px;
  height: calc(100dvh - 75px);
  width: 100%;
  z-index: 1090;

  display: block !important;
  padding-left: unset !important;
  padding-top: 100px;
}

.itemNavLink {
  display: block;
  width: 100%;
  text-align: center;
}
  
.itemNavLink a {
  color: #FFF;
  font-family: MontserratSemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  width: 100%;
  height: fit-content;
  display: block;
  padding: 10px;
}

.itemNavLink {
  position: relative;
  list-style: none;
}


/* Agrega estilos para el menú desplegable */
.hasDropdown {
  position: relative; /* Para establecer el contexto de posición para el menú desplegable */
}
.open .dropdown {
  display: block; /* Muestra el menú desplegable cuando el estado open está activo */
}

.dropdown {
  display: none;
  position: relative;
  top: 100%;
  background-color: #DF2E38;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Establece un estilo para las opciones del menú desplegable */
.dropdownOption {
  padding: 20px;
  color: #333;
  text-decoration: none;
  display: block;
  
}

.dropdownArrow {
  width: 10px; 
  height: 10px; 
  margin-left: 30px; 
  display: inline-block;
}
/* 
.hasDropdown:hover .dropdown {
  display: block;
} */