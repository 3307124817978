#page>h1 {
    color: rgba(0, 0, 0, 0.80);
    font-family: InterRegular;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 12px;
  }
  
  #page>p {
    color: rgba(0, 0, 0, 0.80);
    font-family: InterRegular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  #page .body {
    display: block;
    margin-top: 42px;
  }
  
  .filterSearchBar {
    width: clamp(441px, 100%, 441px);
    display: block;
    position: relative;
    margin-bottom: 10px;
  }
  
  .filterSearchBar .searchBar {
    overflow: hidden;
    border-radius: 30px;
    position: relative;
    box-shadow: 0px 4px 8px 0px #00000020;
  }
  
  .searchBar input[type=text] {
    width: clamp(441px, 100%, 441px);
    padding: 11px 26px;
    color: rgba(0, 0, 0, 0.40);
    font-family: MontserratMedium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 30px;
    border: none;
    max-height: 36px;
    outline: none;
  }
  
  .searchBar input[type=text]::placeholder {
    color: rgba(0, 0, 0, 0.40);
    font-family: MontserratMedium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  
  .searchIcon {
    display: block;
    width: 26px;
    height: 26px;
    background: #F0E7E7;
    position: absolute;
    right: 8px;
    top: 5px;
    border-radius: 50%;
    z-index: 1020;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 180ms linear;

  }
  
  .searchIcon:hover {
    background: #d2c9c9;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 180ms linear;
  }
  
  .containerFilters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .filterTools {
    display: flex;
    flex-flow: row nowrap;
    gap: 32px;
    align-items: center;
    justify-content: flex-end;
  }


  .containerButtons{
    display: flex;
    align-items: center;
  }
  .buttonAsignar{
    display: flex;
    background: #ce0808;
    color: #fff;
    align-items: center;
    justify-content: space-evenly;
    width: 170px;
    height: 40px;
    border-radius: 10px;
    font-size: 12px;
    font-family: MontserratMedium;
  }
  .buttonAsignar.disabled {
    background: #ccc; 
    cursor: not-allowed; 
  }
  .buttonExcel{
    display: flex;
    background: #ce0808;
    color: #fff;
    align-items: center;
    justify-content: space-evenly;
    width: 170px;
    height: 40px;
    border-radius: 10px;
    font-size: 12px;
    font-family: MontserratMedium;
    margin-left: 10px;
  }
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1201;
  }
  
  .modalAsignature {
    background: #fff;
    width: 513px;
    height: 277px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    display: flex;
    padding: 50px 60px 0px 60px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* z-index: 999; */
  }
  .titleModal{
      font-size: 22px;
  }
  .textModal{
    color: rgba(0, 0, 0, 0.60);
    font-family: MontserratMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: start;
  }
  .inputDropdownModal{
    width: 250px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #F7F4F4;
    color: rgba(0, 0, 0, 0.50);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    padding: 10px;
    margin-top: 30px;
    position: relative;
  }
  
  .btnModalAsignature{
    width: 81px;
    height: 35px;
    border-radius: 5px;
    background: var(--secondary, #FC5555);
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 10px;


  }
  .btnModalAsignature.disable{
    background: #ccc; 
    cursor: not-allowed; 
  }


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  @media screen and (max-width: 1195px) {
    .containerFilters {
      /* flex-flow: column wrap;
      gap: 28px; */
      align-items: flex-start;
    }
  }

  @media screen and (max-width: 834px) {
  
    .containerFilters {
      /* flex-flow: column wrap;
      gap: 28px; */
      align-items: flex-start;
    }
    .containerButtons{
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
 
    .filterSearchBar {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      gap: 20px;
      margin-bottom: 10px;
    }
  
    .searchBar {
      width: clamp(287px, 287px, 287px);
    }
  
    .searchBar input[type=text] {
      width: clamp(287px, 287px, 287px);
    }
  }
  
  @media screen and (max-width: 480px) {
    .modalAsignature {
      background: #fff;
      width: 400px;
      height: 277px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      text-align: center;
      display: flex;
      padding: 50px 60px 0px 60px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      /* z-index: 999; */
    }
    .filterTools {
      gap: 13px;
      justify-content: center;
      width: 100%;
    }
  
    .filterTools button span {
      display: none;
    }
  
    .filterTools button {
      border-radius: 50%;
      width: clamp(30px, 30px, 30px);
      height: clamp(30px, 30px, 30px);
      padding: 0;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
    }
  
    #filterSelect {
      width: clamp(155px, 155px, 155px);
    }
  
    .searchBar input[type=text],
    .searchBar {
      width: 100%;
      max-width: 100%;
    }
  
    #page .body {
      margin-top: 32px;
    }
  
    .actionTools {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      flex-grow: 1;
      gap: unset;
    }
  
      
  .toolButton {
    width: 60px;
    height: 30px;
    font-family: 'InterBold';
    border-radius: 10px;
    background: #ce0808;
    color: #F0E7E7;
    border: none;
    transform: scale(1);
    transition: all 180ms linear;
  }
  .buttonAsignar{
    display: flex;
    background: #ce0808;
    color: #fff;
    align-items: center;
    justify-content: space-evenly;
    width: 130px;
    height: 30px;
    border-radius: 5px;
    font-size: 10px;
    font-family: MontserratMedium;
  }
  .buttonAsignar.disabled {
    background: #ccc; /* Color gris para el estado deshabilitado */
    cursor: not-allowed; /* Cambia el cursor cuando está deshabilitado */
  }
  .buttonExcel{
    display: flex;
    background: #ce0808;
    color: #fff;
    align-items: center;
    justify-content: space-evenly;
    width: 130px;
    height: 30px;
    border-radius: 5px;
    font-size: 10px;
    font-family: MontserratMedium;
  }
  }